export enum FeatureFlagEnum {
  PurchaseOrderRevamp = 'purchase-order-revamp',
  MultiTemplateEnabled = 'multi-template-enabled',
  MultiTemplateLogReceipts = 'multi-template-log-receipts-on-plugin',
  WebSocketPingPongEnabled = 'web-socket-ping-pong-enabled',
  InventoryAPIV3 = 'inventory-api-v3',
  CustomFields = 'custom-fields',
  CustomFieldsPackages = 'custom-field-packages',
  PurchaseOrderRevampAdditionalCost = 'purchase-order-revamp',
  PurchaseOrderRevampDiscount = 'purchase-order-revamp',
  PurchaseOrderRevampDeliveryDate = 'purchase-order-revamp',
  ForceOffline = 'force-offline',
  PurchaseOrderRevampImportProduct = 'purchase-order-revamp',
  PurchaseOrderAddSimpleProduct = 'purchase-order-revamp',
  RegisterReport = 'register-report',
  StockCountImportProducts = 'stock-count-import-products',
  PublishProducts = 'publish-products',
  ZatcaEnabled = 'zatca-enabled',
  ReferralProgram = 'referral-program',
  OfflineInventorySyncInterval = 'offline-inventory-sync-interval',
  SoftPosEnabled = 'softpos-enabled',
  SubscriptionPlanCustomAttributes = 'subscription-plans-custom-attributes',
  OfflineFirstEnabled = 'offline-first-enabled',
  CustomFieldsMetabaseReports = 'custom-fields-metabase-reports',
  posUiWebUIChanges = 'pos-ui-web-ui-changes',
  OfflineFirst = 'offline-first',
  SubscriptionsV2 = 'subscriptions-v2',
  PromotionRevamp = 'promotion-revamp-enabled',
  Marketing = 'marketing',
  PosUiWebUIChanges = 'pos-ui-web-ui-changes',
  RecurringExpense = 'recurring-expense',
  AccountingOpeningBalance = 'accounting-opening-balance',
  StcManagerCustomerId = 'stc-manager',
  DashboardDateRange = 'dashboard-date-range',
  CoBrowsingEnabled = 'enable-co-browsing',
  PosReborn = 'pos-reborn',
  PurchaseOrderRevampV2 = 'purchase-order-revamp-v2',
  IssueDatePurchaseOrderRevampV2 = 'issue-date-purchase-order-revamp-v2',
  StockCountRevamp = 'stock-count-revamp-v2',
  StockControlRevamp = 'stock-control-revamp',
  TransferStockV2 = 'transfer-stock-control-service',
  IntegrationsV2 = 'integrations-v2',
  IntegrationsV2Orders = 'integrations-v2-orders',
  ProductListingV4 = 'product-listing-v4',
  SmsOtp = 'sms-otp',
  PermissionsV2 = 'permissions-v2',
  CashManagementV2 = 'cash-management-v2',
  StockControlReportCubeJs = 'stock-control-cubejs',
  TaxesReportCubeJs = 'taxes-report-cubejs',
  DatatableV3ProductsList = 'datatable-v3-products-list',
  InventoryReportCubeJs = 'inventory-report-cubejs',
  B2BNewFlow = 'B2BNewFlow',
  AccountingActivityService = 'accounting-activity-service',
  RewaaOnboarding = 'rewaa-onboarding',
  CustomFieldChargeBeeEnabled = 'custom-fields-chargebee',
  PermissionsUserCreationV2 = 'permissions-v2-users',
  OfflineInvoiceSyncRevamp = 'offline-invoice-sync-revamp',
  POSDiscountUxV2 = 'pos-discount-ux-v2',
  PosReceiptCurrency = 'pos-receipt-currency',
  DoomsDaySubscriptionV2 = 'doomsday-subscriptions-v2',
  CustomerReceiveDebitDesignRevamp = 'customer-receive-debit-design-revamp',
  TrialBalanceReportRevamp = 'trial-balance-report-revamp',
  AccountingPDFExport = 'accounting-pdf-export',
  ReportsV2 = 'reports-v2',
  OfflineInvoicesCountPollingInterval = 'offline-invoices-count-polling-interval',
  RewaaPayEnabled = 'rewaa-pay-enabled',
  MinimalistInvoiceDesign = 'minimalist-invoice-design',
  ExpenseSummaryFromCube = 'expense-summary-from-cube',
  StcMangerInvoicing = 'stc-manager-invoice',
  CustomLoader = 'custom-loader',
  OpeningBalanceSetup = 'opening-balance-setup',
  LoadingSkeleton = 'loading-skeleton',
  RestrictFeatureUsage = 'restrict-feature-usage',
  BillingInfoCheckout = 'billing-info-in-checkout',
  OcrService = 'ocr-service',
  NewPurchaseOrderInAmountCollected = 'new-purchase-order-in-amount-collected',
  ProductMovementReportV2 = 'product-movement-report-v2',
  TobaccoTaxEnabled = 'tobacco-tax-enabled',
  AccountingTransactionsMapping = 'accounting-transactions-mapping',
  WarehouseCubeReports = 'warehouse-cube-reports',
  SalesCubeReports = 'sales-report-cubejs',
  SearchByCustomer = 'search-by-customer',
  CoaUnlinkPaymentMethodsDialog = 'coa-unlink-payment-methods-dialog',
  ZatcaQRCodeEnabled = 'zatca-qr-code-enabled',
  JournalEntriesEsSearch = 'journal-entries-es-search',
  JeSearchHighLights = 'je-search-highlights',
  ShowTaxDeclarationOtherTax = 'show-tax-declaration-other-tax',
  ExpenseV2 = 'expense-v2',
  AdditionalCostTax = 'additional-cost-tax',
}
